<template>
  <div class="layout-header-section d-lg-flex d-block justify-content-between">
    <div class="header-label col-lg-6 col-12">Main Account Statement</div>
    <div class="col-lg-6 col-12 text-end d-flex justify-content-end custom-flex-cloumn-mob">
      <button type="button" @click="AccountStatementFilterModalOpen()" class="header-filter-btn-outer btn btn-light"
        title="Filter" :disabled="dissableFilterButton == true">
        <span class="custom-filter-apply-alert" v-if="filterflag"></span>
        <i class="pi pi-filter custom-text-primary" aria-hidden="true"></i>
      </button>
    </div>
  </div>
  <!-- start for AccountStatement -->
  <div class="company-detail-box-outer" style="height: calc(100vh - 141px)">
    <div v-if="!showdataloader">
      <div class="comman-overflow-scroll-outer mb-3">
        <div class="accordion" id="accordionCompanyBilling" v-if="accountStatementEntries != null">
          <div class="accordion-item mb-2" v-for="(month, index) in accountStatementEntries" :key="index">
            <h2 class="accordion-header" :id="`heading${index}Month`">
              <button v-if="monthList.map(d => d.monthName).includes(index)"
                class="accordion-button collapsed custom-accordion-focus-out-btn" type="button" data-bs-toggle="collapse"
                :data-bs-target="`#${index}`" aria-expanded="false" :aria-controls="`${index}`">
                {{ index }}
              </button>
            </h2>
            <div v-if="monthList.map(d => d.monthName).includes(index)" :id="`${index}`"
              class="accordion-collapse collapse" :aria-labelledby="`heading${index}month`"
              data-bs-parent="#accordionCompanyBilling">
              <div class="accordion-body">
                <div class="custom-ultima-datatable custom-tax-invoice-scroll-datatable mt-3" style="height: calc(100vh - 360px)">
                  <DataTable :value="month" :scrollable="true" scrollHeight="flex" :paginator="false" :lazy="true"
                    :rowHover="true" :totalRecords="totalRecords" @page="onPage($event)" responsiveLayout="scroll"
                    :loading="loading">
                    <template v-if="!loading" #empty>No records found.</template>
                    <template #loading><i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i></template>
                    <Column field="date" header="Date" headerStyle="width: 10%" bodyStyle="width: 10%">
                      <template #body="{ data }">
                        <div class="label-subheading" v-if="data.transaction_type == 2">{{ format_date(data.credit_date) }}</div>
                        <div class="label-subheading" v-if="data.transaction_type == 1">{{ format_date(data.debit_date) }}</div>
                      </template>
                    </Column>
                    <Column field="particulars" header="Particulars" headerStyle="width: 45%" bodyStyle="width: 45%">
                      <template #body="{ data }">
                        <div>
                          <div class="label-subheading">{{ data.perticulars }}</div>
                          <div class="mt-1 text-muted" v-if="data.debit_for == 1">({{ data.debit_component_count ? data.debit_component_count : "0" }} * {{
                            vueNumberFormat(data.debit_component_charge, {
                              prefix: '₹ ', decimal: '.', thousand: ',', precision: 2,
                              acceptNegative: true
                            })
                          }}) = {{ vueNumberFormat(data.debit_amount, {
  prefix: '₹ ', decimal: '.', thousand: ',', precision: 2,
  acceptNegative: true
})
}}</div>
                        </div>
                      </template>
                    </Column>
                    <Column field="credit" header="Credits" headerStyle="width: 15%" bodyStyle="width: 15%"
                      class="justify-content-end">
                      <template #body="{ data }">
                        <div class="label-subheading text-success">
                          {{ vueNumberFormat(data.credit_amount, {
                            prefix: '₹ ', decimal: '.', thousand: ',', precision: 2,
                            acceptNegative: true
                          })
                          }}
                        </div>
                      </template>
                    </Column>
                    <Column field="debit" header="Debits" headerStyle="width: 15%" bodyStyle="width: 15%"
                      class="justify-content-end">
                      <template #body="{ data }">
                        <div class="label-subheading text-danger">
                          {{ vueNumberFormat(data.debit_amount, {
                            prefix: '₹ ', decimal: '.', thousand: ',', precision: 2,
                            acceptNegative: true
                          })
                          }}
                        </div>
                      </template>
                    </Column>
                    <Column field="balance" header="Balance" headerStyle="width: 15%" bodyStyle="width: 15%"
                      class="justify-content-end">
                      <template #body="{ data }">
                        <div class="label-subheading">
                          {{ vueNumberFormat(data.balance_amount, {
                            prefix: '₹ ', decimal: '.', thousand: ',', precision: 2,
                            acceptNegative: true
                          })
                          }}
                        </div>
                      </template>
                    </Column>
                  </DataTable>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="text-center py-4">No records found.</div>
        </div>
      </div>
    </div>
    <div v-if="showdataloader">
        <div class="custom-modal-spinner-loader">
          <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
        </div>
      </div>
  </div>
  <!-- filter start here -->
  <div class="modal-mask" v-if="accountstatementmodalstatus">
    <div class="modal-dialog modal-xs modal-dialog-scrollable modal-fullscreen ms-auto custom-modal-outer">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">Filter By</h5>
          <button type="button" class="btn-close" @click="AccountStatementFilterModalClose()"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-12">
              <div class="custom-form-group">
                <label class="form-label">From Date</label>
                <Calendar v-model="fromdate" :showIcon="true" class="custom-ultima-calendar" placeholder="DD/MM/YYYY"
                  dateFormat="dd/mm/yy" :manualInput="false" :monthNavigator="true" :yearNavigator="true"
                  :yearRange="'2000:' + +new Date().getFullYear()" appendTo="body" />
              </div>
            </div>
            <div class="col-lg-12 col-md-12 col-12">
              <div class="custom-form-group">
                <label class="form-label">To Date</label>
                <Calendar v-model="todate" :showIcon="true" class="custom-ultima-calendar" placeholder="DD/MM/YYYY"
                  dateFormat="dd/mm/yy" :manualInput="false" :monthNavigator="true" :yearNavigator="true"
                  :yearRange="'2000:' + +new Date().getFullYear()" appendTo="body" />
              </div>
            </div>
            <div class="col-lg-12 col-md-12 col-12">
              <div class="custom-form-group">
                <label for="accountentries" class="form-label">Show Entries</label>
                <div class="custom-group-radio-box-btn">
                  <input type="radio" class="btn-check" v-model="accountentries" name="accountentries" id="credit"
                    autocomplete="off" v-bind:value="2" />
                  <label class="btn custom-radio-box-btn text-capitalize" for="credit">Credit</label>
                  <input type="radio" class="btn-check" v-model="accountentries" name="accountentries" id="debit"
                    autocomplete="off" v-bind:value="1" />
                  <label class="btn custom-radio-box-btn text-capitalize" for="debit">Debit</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="col-12 m-0">
            <div class="d-flex justify-content-between">
              <button :disabled="filterButtonShow == false" class="btn filter-apply-btn" @click="
                applyFilteStatement(
                  fromdate,
                  todate,
                  accountentries,
                  billingCompany
                )
                ">
                Apply Filter
              </button>
              <button class="btn btn-link filter-reset-btn" @click="resetFilterMonth">
                Reset All
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- filter end here -->
</template>
<script>
import ApiService from "../../../service/ApiService";
import moment from "moment";
export default {
  data() {
    return {
      loading: false,
      accountstatementmodalstatus: false,
      fromdate: "",
      todate: "",
      accountStatementEntries: {},
      accountentries: null,
      billingCompany: null,
      filterflag: false,
      dissableFilterButton: false,
      filterButtonShow: false,
      totalRecords: 0,
      showdataloader: false,
      isData: false,
      monthList: [
        {
          monthName: "January",
        },
        {
          monthName: "February",

        },
        {
          monthName: "March",
        },
        {
          monthName: "April",
        },
        {
          monthName: "May",
        },
        {
          monthName: "June",
        },
        {
          monthName: "July",
        },
        {
          monthName: "August",
        },
        {
          monthName: "September",
        },
        {
          monthName: "October",
        },
        {
          monthName: "November",
        },
        {
          monthName: "December",
        },
      ],
    };
  },

  ApiService: null,
  created() {
    this.ApiService = new ApiService();
  },

  mounted() {
    this.loading = true;
    this.getHostAccountStatement({
      accountentries_status: 1,
      billing_company: 1,
      fromdate: this.fromdate,
      todate: this.todate,
    });
  },
  watch: {
    fromdate() {
      this.filterChanges();
    },
    todate() {
      this.filterChanges();
    },
    accountentries() {
      this.filterChanges();
    },
  },
  methods: {
    format_date(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
      } else {
        return 'N/A';
      }
    },
    getHostAccountStatement(e) {
      this.loading = true;
      this.showdataloader = true;
      this.ApiService.getHostAccountStatement(e).then((data) => {
        if (data.status == 200) {
          this.accountStatementEntries = data.data;
          // if (this.accountStatementEntries.January) {
          //   this.isData = true;
          // }
          // if (this.accountStatementEntries.February) {
          //   this.isData = true;
          // }
          // if (this.accountStatementEntries.March) {
          //   this.isData = true;
          // }
          this.loading = false;
          this.showdataloader = false;
        } else {
          this.accountStatementEntries = null;
          this.showdataloader = false;
        }
      });
    },
    onPage(event) {
      this.loading = true;
      this.page_no = event.page;
      this.getHostAccountStatement({
        type: 1,
        page_no: this.page_no,
        fromdate: this.fromdate,
        todate: this.todate,
        accountentries_status: this.accountentries,
      });
    },
    filterChanges() {
      if (
        this.fromdate != "" ||
        this.todate != "" ||
        this.accountentries != null
      ) {
        this.filterButtonShow = true;
      } else {
        this.filterButtonShow = false;
      }
    },
    applyFilteStatement(fromdate, todate, accountentries, billingCompany) {
      this.loading = true;
      this.filterflag = true;
      this.accountentries = accountentries;
      this.billingCompany = billingCompany;
      this.accountstatementmodalstatus = false;
      if (fromdate != "" && fromdate != null) {
        this.fromdate = moment(fromdate).format("YYYY-MM-DD");
      }
      if (todate != "" && todate != null) {
        this.todate = moment(todate).format("YYYY-MM-DD");
      }
      this.getHostAccountStatement({
        type: 1,
        page_no: this.page_no,
        fromdate: this.fromdate,
        todate: this.todate,
        accountentries_status: this.accountentries,
        billingCompany: this.billingCompany,
      });
    },
    resetFilterMonth() {
      this.fromdate = "";
      this.todate = "";
      this.accountentries = null;
      this.getHostAccountStatement({
        accountentries_status: 1,
        billing_company: 1,
        fromdate: this.fromdate,
        todate: this.todate,
      });
      this.filterflag = false;
    },

    AccountStatementFilterModalOpen() {
      this.accountstatementmodalstatus = true;
    },

    AccountStatementFilterModalClose() {
      this.accountstatementmodalstatus = false;
    },
  },
};
</script>
<style scoped>
.main-account-scroll-datatable {
  height: calc(100vh - 260px);
}

.company-detail-box-outer {
  position: relative;
  overflow: auto;
}
.company-detail-box-outer::-webkit-scrollbar {
  display: block;
}

.company-detail-box-outer::-webkit-scrollbar {
  width: 0.3em;
  height: 0.3em;
}

.company-detail-box-outer::-webkit-scrollbar-thumb {
  background-color: rgb(113 115 119 / 49%);
  border-radius: 3px;
}
.custom-accordion-focus-out-btn {
  user-select: none;
}

.custom-accordion-focus-out-btn:focus {
  box-shadow: 0 0 0 0.1rem rgba(13,110,253,.25);
}
</style>